import React, {Component} from "react";
import {Button} from "react-bootstrap";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";


class ExportGiftListCSV extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    cleanData(dirtyData){
        let exportData = [];

        for(var z=0; z<dirtyData.length; z++){
            if(this.props.type===dirtyData[z].msg_type){
                dirtyData[z].amount = '5';
                exportData.push(dirtyData[z]);
            }
        }

        return exportData;
    }

    allHeaders(dirtyData){
        let headers = [];
        headers.push({ 'label': 'FirstName', 'key': 'first_name'});
        headers.push({ 'label': 'LastName', 'key': 'last_name'});
        if(this.props.type==='SMS'){
            headers.push({ 'label': 'Phone', 'key': 'phone'}); 
        } else if(this.props.type==='EMAIL'){
            headers.push({ 'label': 'Email', 'key': 'email'}); 
        }
        headers.push({ 'label': 'RewardAmount', 'key': 'amount'});
        headers.push({ 'label': 'RecipientID', 'key': 'tv_survey_id'});
        return headers;
    }

    render() {
        return (
            <Button className='float-right btn btn-primary exportButton'>
                <CSVLink data={this.cleanData(this.props.csvData)} headers={this.allHeaders(this.props.csvData)} filename={this.props.fileName}>Export {this.props.type} CSV</CSVLink>
            </Button>
        )

    }
}

ExportGiftListCSV.propTypes = {
    csvData: PropTypes.array.isRequired,
    fileName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default ExportGiftListCSV;
