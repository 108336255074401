import React, {Component} from "react";
import {Button} from "react-bootstrap";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";


class ExportStaffEngagementCSV extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    cleanData(dirtyData){
        let exportData = [];
        
        for (var i = dirtyData.length - 1; i >= 0; i--) {
            var thisLine = dirtyData[i];
            var dynamicLine = {};
            var badges = [];
            dynamicLine.id = thisLine.key;
            dynamicLine.name = thisLine.name;
            dynamicLine.engagements = thisLine.engagements; 
            
            for (var k = thisLine.badges.length - 1; k >= 0; k--) {
                let newKey = thisLine.badges[k].endorsement_id.toString();
                if(thisLine.badges[k].severityLevel!==undefined && thisLine.badges[k].severityLevel!==null){
                    newKey +=' - ' + thisLine.badges[k].severityLevel;
                }

                if(badges[thisLine.badges[k].endorsement_id.toString()]===undefined){
                    badges[newKey] = { 'desc': thisLine.badges[k].description, 'key': newKey, 'count': 1 };    
                } else{
                    badges[newKey].count= parseInt(badges[thisLine.badges[k].endorsement_id.toString()].count)+1;
                }

            }

            for(var key in badges) {
                dynamicLine[badges[key].key] = badges[key].count;
            }   

            exportData.push(dynamicLine);
        }

        return exportData.reverse();
    }

    allHeaders(dirtyData){
        let headers = [];
        let badges = [];
        headers.push({ 'label': 'ID', 'key': 'id'});
        headers.push({ 'label': 'Name', 'key': 'name'});
        headers.push({ 'label': 'Total Engagements', 'key': 'engagements'});
        for (var j = dirtyData.length - 1; j >= 0; j--) {
            for (var i = dirtyData[j].badges.length - 1; i >= 0; i--) {
                let newKey = dirtyData[j].badges[i].endorsement_id.toString();
                if(dirtyData[j].badges[i].severityLevel!==undefined && dirtyData[j].badges[i].severityLevel!==null){
                    newKey +=' - ' + dirtyData[j].badges[i].severityLevel;
                    badges[newKey] = { 'desc': dirtyData[j].badges[i].description + ' - ' + dirtyData[j].badges[i].severityLevel, 'key': newKey };
                } else{
                    badges[newKey] = { 'desc': dirtyData[j].badges[i].description, 'key': dirtyData[j].badges[i].endorsement_id.toString() };
                }
                
            }
        }

        for(var key in badges) {
            headers.push({ 'label': badges[key].desc, 'key': badges[key].key});
        }   
        return headers;
    }

    render() {
        return (
            <Button className='float-right btn btn-primary exportButton'>
                <CSVLink data={this.cleanData(this.props.csvData)} headers={this.allHeaders(this.props.csvData)} filename={this.props.fileName}>Export CSV</CSVLink>
            </Button>
        )

    }
}

ExportStaffEngagementCSV.propTypes = {
    csvData: PropTypes.array.isRequired,
    fileName: PropTypes.string.isRequired
};

export default ExportStaffEngagementCSV;
