import React, {Component} from "react";
import {
    Dropdown,
    Table
} from "react-bootstrap";
import { listLocations, listLocationMappingGroups, listGiftReport, viewSurvey } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Link } from 'react-router-dom';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';
import ExportGiftListCSV from './../../Utilities/ExportGiftListCSV';

class GiftReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
          activeKey: 1,
          currentFilter: 8,
          filter: 'lastweek',
          locationFilter: 0,
          currentFilterName: '  Last Week ',
          currentFilterLocation: ' All Locations ',
          showCustomDate: false,
          showLocations: true,
          reportFrom: null,
          reportTo: null,
          businessLine: null
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.runReport = this.runReport.bind(this);
        this.getLastWeekDates = this.getLastWeekDates.bind(this);

        this.props.listLocations(this.props.tvClient).then(()=>{
                if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                    if(this.props.roleLocations!==undefined){
                        var newLocation = null;
                        var newLocationName = '';
                        for(var i=0; i<this.props.roleLocations.length;i++){
                            newLocation = this.props.roleLocations[i];
                            break;
                        }
                        if(newLocation!==null){
                            for (var i = this.props.locations.length - 1; i >= 0; i--) {
                              if(this.props.locations[i].location_id.toString()===newLocation.toString()){
                                    newLocationName = ' ' + this.props.locations[i].name + ' ';     
                                    break;
                              }
                            }
                        }
                        if(newLocation!==null && newLocationName!==''){
                            this.setState({ locationFilter: newLocation, currentFilterLocation: newLocationName }, ()=>{
                                this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, this.state.filter, null);
                                this.props.listLocationMappingGroups(this.props.accessToken);
                            });     
                        }
                    } 
                } 
        });


    }   

    handleFromChange(from) {
      this.setState({ reportFrom: from });
    }
    handleToChange(to) {
      this.setState({ reportTo: to });
    }   

    viewSurvey(id){
        this.props.viewSurvey(this.props.tvClient, id);
    }

    runReport(){
      this.setState({ filter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
      this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), null);
    } 

    handleLocationFilter(selectedKey){
      
      if(parseInt(selectedKey)===0){
        this.setState({ locationFilter: 0, currentFilterLocation: ' All Locations '});
        this.props.listGiftReport(this.props.tvClient, selectedKey, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), null);
      }
      else{
        for (var i = this.props.locations.length - 1; i >= 0; i--) {
          if(this.props.locations[i].location_id===parseInt(selectedKey)){
            this.setState({ locationFilter: selectedKey, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
            this.props.listGiftReport(this.props.tvClient, selectedKey, this.state.filter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), null);
            break;
          }
        }
        
      }
    }

    getLastWeekDates() {
      const today = new Date();
      const currentDayOfWeek = today.getDay();
      const daysSinceLastMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    
      const lastMonday = new Date(today);
      lastMonday.setDate(today.getDate() - daysSinceLastMonday - 7);
    
      const lastSunday = new Date(lastMonday);
      lastSunday.setDate(lastMonday.getDate() + 6);
    
      const formatDate = (date) => {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
      };
    
      const mondayFormatted = formatDate(lastMonday);
      const sundayFormatted = formatDate(lastSunday);
    
      return `Date Range (${mondayFormatted} - ${sundayFormatted})`;
    }

  fileNameDate(){
    if(this.state.filter==='custom'){
      return this.state.currentFilterName;
    } else {
      return this.getLastWeekDates();
    }
  }

    handleLocationMappingFilter(selectedKey){
      if(selectedKey.toString()==='-1'){
              this.setState({ businessLine: null}, ()=>{
                if(this.state.filter==='custom'){
                  this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), null);
                } else{
                  this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, this.state.filter, null, null, null); 
                }
              });

      } else{

          for(var i=0; i<this.props.locationMapping.length; i++){
            if(parseInt(i)===parseInt(selectedKey)){
              this.setState({ businessLine: this.props.locationMapping[i].location_resource_alt}, ()=>{
                if(this.state.filter==='custom'){
                    this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), null);
                } else{
                    this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, this.state.filter, null, null, null); 
                }
              });
              break;
            }
          }
      }
      
    }


    handleFilter(selectedKey) {
      if(selectedKey==1){
        this.setState({ filter: 'lastweek', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Week ' }, () => {
          this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, this.state.filter, null, null, null);         
        });
      } else if(selectedKey==2){
        this.setState({ filter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, this.state.filter, null, null, null);       
        });
      } else if(selectedKey==3){
        this.setState({ filter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
          this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, this.state.filter, null, null, null); 
        });
      } else if(selectedKey==6){
        this.setState({ showCustomDate: true });
      }
      if(selectedKey==8){
        this.setState({ filter: 'lastweek', showCustomDate: false, currentFilterName: ' Last Week ', currentFilter: selectedKey }, () => {
          this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, this.state.filter, null, null, null);
        });
        
      }
    }

    renderScoreToName(score){
        let text = '-'
        if(score.toString()==='5'){
            text = 'Excellent';
        } else if(score.toString()==='4'){
            text = 'Very Good';
        } else if(score.toString()==='3'){
            text = 'Average';
        } else if(score.toString()==='2'){
            text = 'Dissatisfied';
        } else if(score.toString()==='1'){
            text = 'Very Dissatisfied';
        }
        return text;
    }

    componentDidMount(){
        //this.props.viewAdminDashboardEndorsements(this.props.accessToken);
        if(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly){
          this.props.listGiftReport(this.props.tvClient, this.state.locationFilter, this.state.filter, null);
          this.props.listLocationMappingGroups(this.props.accessToken);  
        }
        
    } 

    tableHeader(sortKey, label) {
        return <th className="sortable"
                   onClick={this.toggleSortDirection(sortKey)}>{label} {this.sortIndicator(sortKey)}</th>;
    }

    render() {
       return <div className="reportingPages">
            <h1 className="extraDropDown page-header">
                Patient Gift Report
                    <div className="float-right">
                        <Dropdown
                          title='Filter Report By Date'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterName}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>                    
                            <Dropdown.Item eventKey="1">Last Week (Mon-Sun)</Dropdown.Item>
                            <Dropdown.Item eventKey="6">Custom</Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                      </div>
            {(this.state.showLocations && this.props.locations) && <div className="float-right extraRightMargin">
                      <Dropdown
                          title='Choose a Location'
                          id='filterSurvey'
                          drop="down" onSelect={this.handleLocationFilter.bind(this)}
                        >
                          <Dropdown.Toggle className="btn-default" id="dropdown-basic">
                            {this.state.currentFilterLocation}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>  

                          {(!this.props.isLocationOnly && !this.props.isSurveysReportsOnly) && <Dropdown.Item eventKey={0}>All Locations</Dropdown.Item> }
                      {Object.values(this.props.locations).map(d => {
                         if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
                                if(this.props.roleLocations!==undefined){
                                    for(var i=0; i<this.props.roleLocations.length;i++){
                                        if(this.props.roleLocations[i].toString()===d.location_id.toString()){
                                            return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
                                        }
                                    }
                                } else{
                                    return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                                }
                            } else{
                                return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>    
                            }
                      })}
                      </Dropdown.Menu>
                    </Dropdown>
            </div> }  
                      

              {this.state.showCustomDate && <div className="customDateRange">
                  <p className="prizeDrawTitle">Select the range to run the report for:</p>
                  <label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                      },
                    }} />

                <label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
                    dayPickerProps={{
                      selectedDays: this.state.selectedDay,
                      disabledDays: {
                        after: new Date(),
                        before: this.state.prizeFrom
                      },
                    }} />

              <button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
             
             
              </div>
            }
  
            </h1>

            <p className="case-list-container stand-out-line float-right">Showing data for <span className="text-underline">{(this.state.businessLine!==null && this.state.businessLine!=='') ? this.state.businessLine + ' - ' : ''}{this.state.currentFilterName}</span> at <span className="text-underline">{this.state.currentFilterLocation}</span></p>
                        <div className="case-list-container">
            <h2 className="headerSpacer">Summary Details</h2>
            <p className="col-6">Below are the details for the patient surveys that were completed during the selected date range. Use the respective buttons for creating an Export file for SMS Texting as well as for Emails. </p>
            {(this.state.filter==='lastweek') && <p className="col-6"><b>{this.getLastWeekDates()}</b></p> }
            {(this.state.filter==='custom') && <p className="col-6"><b>{this.state.currentFilterName}</b></p>}
            </div>
            <br/>
            <div className="case-list-container">
                    {this.props.giftDetailsLoading && <Spinner/>}
                     {(!this.props.giftDetailsLoading && this.props.giftDetailsData) && <div>
                        <table className="case-list sortable table">
                          <tbody>
                          <tr>
                            <td><h4>Patients With Email Rewards: {this.props.giftReport.numEmail}</h4></td>
                            <td><ExportGiftListCSV csvData={this.props.giftDetailsData} fileName={'PatientGift-Email-'+ this.fileNameDate() +'.csv'} type='EMAIL' /></td>
                          </tr>
                          <tr>
                            <td><h4>Patients With SMS Rewards: {this.props.giftReport.numSMS}</h4></td>
                            <td> <ExportGiftListCSV csvData={this.props.giftDetailsData} fileName={'PatientGift-Text-'+ this.fileNameDate() +'.csv'} type='SMS' /></td>
                          </tr>
                          </tbody>
                        </table>
                        
                    </div> 
                    }

              {  this.props.giftDetailsData && <Table className="case-list sortable">
                    <thead>
                    <tr>
                        <th>Row ID</th>
                        <th>Survey Type</th>
                        <th>Respondent</th>
                        <th>Created</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.props.giftDetailsData.map((c, i) => {
                            return <tr className={(i % 2)===0 ? "surveyRow" : "surveyRow altRowColor"} onClick={()=> this.viewSurvey(c.survey_id)} key={c.survey_id}>
                                <td>{i+1}</td>
                                <td>{c.msg_type}</td>
                                <td>{c.first_name + ' ' + c.last_name}</td>
                                <td>{dateFormat(c.created, "shortDate")}</td>
                            </tr>;
                        })
                    }
                    </tbody>
                </Table> }

                    {(this.props.giftDetailsData && this.props.giftDetailsData.length===0) && <p>No data to display.</p>}

            </div><br/><br/>  



        </div>
    }
}

const mapStateToProps = state => {
    return {
        tvClient: state.login.tvClient,
        accessToken: state.login.tvClient.accessToken,
        giftDetailsLoading: state.giftReport.loading,
        giftDetailsData: state.giftReport.surveys,
        giftReport: state.giftReport,
        locations: state.locationList.locations,
        locationMapping: state.locationMappingGroupList.mappings,
        isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
        isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
        roleLocations: state.login.user.attributes.locations
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listLocations: (...params) => dispatch(listLocations(...params)),
        listGiftReport: (...params) => dispatch(listGiftReport(...params)),
        viewSurvey: (...params) => dispatch(viewSurvey(...params)),
        listLocationMappingGroups: (...params) => dispatch(listLocationMappingGroups(...params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftReport);
