import React, {Component} from "react";
import PropTypes from "prop-types";

class StaffBadges extends Component {

    render() {
        
        var badges = this.props.items;
        var badgeList = [];

        if(badges === null || badges === undefined) {
            badges = [];
        }

        for (var i = badges.length - 1; i >= 0; i--) {
            var found = false;
            for (var j = badgeList.length - 1; j >= 0; j--) {
                if(badges[i] !== null && badgeList[j] !== null) {
                    if(badges[i].image === badgeList[j].image) {
                        // Check severity and severityLevel if applicable
                        if(badges[i].severity !== undefined && badgeList[j].severity !== undefined) {
                            if (badges[i].severityLevel === badgeList[j].severityLevel) {
                                found = true;
                            }
                        } else if(badges[i].severity === undefined && badgeList[j].severity === undefined) {
                            found = true;
                        } else {
                            found = false;
                        }

                        if(found) {
                            if(badgeList[j].count === undefined) {
                                badgeList[j].count = 1;
                            } else {
                                badgeList[j].count = parseInt(badgeList[j].count, 10) + 1;
                            }
                            break;
                        }
                    }
                }
            }

            if(!found && badges[i] !== null) {
                var newBadge = {
                    image: badges[i].image,
                    severity: badges[i].severity,
                    severityLevel: badges[i].severityLevel,
                    count: 1,
                    description: badges[i].description
                    // Add other properties as needed
                };

                badgeList.push(newBadge);
            }
        }
        
        badgeList.sort(function(a,b){
            return b.count - a.count;
        });

        return <div className="badgeListContainer">
            {
                        badgeList.map((c,index) => {
                            let severity = false;
                            let severityName = '';
                                                        
                            let severityOption = c.severity;
                            if(severityOption!==undefined){
                                severity = true;
                                if(c.severityLevel==='Okay'){ severityName = 'average'; }
                                if(c.severityLevel==='Bad'){ severityName = 'bad'; }
                                if(c.severityLevel==='Great'){ severityName = 'good'; }
                            }
                            
                            return <span key={c.image+''+index} alt={'Given ' + c.count + ' times'} >
                                <div className="badge-card__icon-wrapper">
                                    <p className="badgeCountArea">{c.count}</p>
                                    <img className='img-responsive badge-card__icon' src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image} alt={'Given ' + c.count + ' times'}/>
                                    {severity ? <div className={""+'badge-card__icon-overlay ' + severityName}></div> : null}
                                </div>
                                <p alt={'Given ' + c.count + ' times'}>{c.description}</p>
                            </span>
                        })
            }
        </div>;
    }
}

StaffBadges.propTypes = {
    items: PropTypes.array.isRequired
};

export default StaffBadges;

