/**
 * TrueDiagnostics stores PII in TrueVault, and non-PII with a NodeJS server. The client in this file
 * makes it easy for application code to access this NodeJS server, by insulating the rest of the application
 * from the HTTP requests expected by the NodeJS server.
 */

import  { handleResponseError } from "../src/utils/errorHandler";
class InternalApiClient {


		constructor(urlPrefix) {
				this.urlPrefix = urlPrefix;
				this.intakeInstance = null;
				this.currentIntakes = [];
				this.intakeLocation = 0;
		}

		setIntakeInstance(flag){
				this.intakeInstance = flag;
		}

		getIntakeInstance(){
				return this.intakeInstance;
		
			}
		setCurrentIntakes(intakes){
			this.currentIntakes = intakes;
		}

		setIntakeLocation(location){
				this.intakeLocation = parseInt(location);
		}

		getCurrentIntakeLocation(){
				return this.intakeLocation;
		}

		getCurrentIntakes(){
				return this.currentIntakes;
		}

		
/*
		closeSseInstance(){
				if(this.sseInstance!==undefined){
						this.sseInstance.close();
						this.sseInstance = undefined;
				}
		}
*/


		headers(tvAccessToken) {
				let _token = undefined;
				let xHost = window.location.host;
				let parts = xHost.includes(':') ? xHost.split(':') : [xHost];
				let hostname = parts[0];
				if (typeof tvAccessToken === "object" && tvAccessToken !== null) {
						_token = tvAccessToken._accessToken
				} else if (typeof tvAccessToken === "string") {
						_token = tvAccessToken
				} else {
						// throw Error("No token provided");
						return {
								'X-Host': hostname,
								'Content-Type': 'application/json'
						}
				}
				return {
						'X-Host': hostname,
						'X-TV-Access-Token': _token,
						'Content-Type': 'application/json'
				};
		}

		fileheaders(tvAccessToken){
			let xHost = window.location.host;
			let parts = xHost.includes(':') ? xHost.split(':') : [xHost];
			let hostname = parts[0];
				if(tvAccessToken!==undefined){
					return {
						'X-Host': hostname,
						'X-TV-Access-Token': tvAccessToken
				}
			} else{
				return {
					'X-Host': hostname
				}
			}
			
		}

		nameSimilar(person, staffArray){
				var isSimilar = false;
				var personName = person.name.toString().toLowerCase();

				for(var i=0; i<staffArray.length; i++){
					 if(staffArray[i].first_name!==undefined){
								if(personName.includes(staffArray[i].first_name.toString().toLowerCase()) && personName.includes(staffArray[i].last_name.toString().toLowerCase()) ){
										isSimilar = true;
										break;
								}
						}
				}

				if(!isSimilar){
						for(var z=0; z<staffArray.length; z++){
								if(personName.includes(staffArray[z].last_name.toString().toLowerCase()) ){
										isSimilar = true;
										break;
								}
						}
				}

				return isSimilar;
		}

		returnStaffByDept(unsortedArr, staffMapping, teamStaffList){
				var sortedArr = [];
				var allMatchedStaffArray = [];
				var checkPriority = false;
				var priorityStaff = [];
				var matchedStaff = null;
				var matchedStaffDept = null;
				if(staffMapping!==undefined && staffMapping.staff!==undefined && staffMapping.staff!==null){
						if(staffMapping.staff.personnel!==undefined && staffMapping.staff.personnel!==null){
								checkPriority = true;
								try{
										priorityStaff = JSON.parse(staffMapping.staff.personnel);
								} catch(e){
										priorityStaff = staffMapping.staff.personnel;
								}

						}
				}

				for(var i=0; i<unsortedArr.length; i++){

						if(sortedArr[unsortedArr[i].department_id]!==undefined){
								if(checkPriority){
										if(this.nameSimilar(unsortedArr[i], priorityStaff)){
												matchedStaff = unsortedArr[i].staff_id;
												allMatchedStaffArray.push(unsortedArr[i]);
												matchedStaffDept = unsortedArr[i].department_id;
												if(unsortedArr[i].approved){
														sortedArr[unsortedArr[i].department_id].unshift(unsortedArr[i]);
												}
										} else{
												if(unsortedArr[i].approved){
														sortedArr[unsortedArr[i].department_id].push(unsortedArr[i]);
												}
										}
								} else{
										if(unsortedArr[i].approved){
												sortedArr[unsortedArr[i].department_id].push(unsortedArr[i]);
										}
								}
						}
						else{
								sortedArr[unsortedArr[i].department_id] = [];
								if(unsortedArr[i].approved){
										sortedArr[unsortedArr[i].department_id].push(unsortedArr[i]);
								}
						}
				}

				var teamReplacement = false;
				var teamArray = [];
				if(teamStaffList.length>0 && matchedStaff!==null){
								// Check to see if we have multiple matches from above
								if(allMatchedStaffArray.length>0){
										// If there are multiple matches we need to look for an exact match
										for(var j=0; j<allMatchedStaffArray.length; j++){
												var nameToMatch = allMatchedStaffArray[j].name.toString().toLowerCase();
												if(nameToMatch.includes(priorityStaff[0].first_name.toString().toLowerCase()) && nameToMatch.includes(priorityStaff[0].last_name.toString().toLowerCase()) ){
														// If we find the exact math the matchedStaff variable needs to change
														matchedStaff = allMatchedStaffArray[j].staff_id;
														break;
												}
										}
								}

								var matchingTeamDept = null;
								for(var v=0; v<teamStaffList.length;v++){
										if(teamStaffList[v].staff_id.toString()===matchedStaff.toString()){
												var matchingTeamDept = teamStaffList[v].department_id;
												for(var z=0; z<teamStaffList.length; z++){
														if(teamStaffList[z].department_id===matchingTeamDept){
																var tempTeam = teamStaffList[z];
																tempTeam.department_id = teamStaffList[z].parent_dept;
																if(tempTeam.approved){
																		teamArray.push(teamStaffList[z]);
																		teamReplacement = true;
																}
														}
												}
												break;
										}
								}
								var updatedTeamDepts = [];
								for(var ii=0; ii<teamArray.length; ii++){
										for(var z=0; z<unsortedArr.length; z++){
												if(teamArray[ii].staff_id.toString()===unsortedArr[z].staff_id.toString()){
														if(updatedTeamDepts[unsortedArr[z].department_id]!==undefined){
																sortedArr[unsortedArr[z].department_id].push(unsortedArr[z]);
														} else{
																updatedTeamDepts[unsortedArr[z].department_id] = true;
																sortedArr[unsortedArr[z].department_id] = [];
																sortedArr[unsortedArr[z].department_id].push(unsortedArr[z]);
														}
												}
										}
								}
				}

				return sortedArr;
		}

		async checkForUser(userId, tvClient){

				let searchOption = {
						"full_document": true,
						"filter_type": "and",
						"filter": {
								"$tv.username": {
										"type":"eq",
										"value": userId,
										"case_sensitive":false
								},
								"$tv.status": {
										"type":"eq",
										"value": 'ACTIVATED'
								},
						}
				};

				const response = await tvClient.searchUsers(searchOption);

				// return response.user;
				return response;
		}

		async sendTextMessage(userId, message, tvClient, locationId, url_id, journeyId, language) {
				const type = 'SMS';
				var host = window.location.host;
				// eslint-disable-next-line
				const sendTextResponse = await fetch(`${this.urlPrefix}/api/survey/sms`, {
						method: 'POST',
						body: JSON.stringify({userId, type, locationId, url_id, journeyId, message, language, host}),
						headers: this.headers(tvClient.accessToken)
				});

				return sendTextResponse;
		}

		async sendEmployerReminder(accessToken, sentId){
				var host = window.location.host;
				const response = await fetch(`${this.urlPrefix}/api/campaigns/survey/reminder`, {
						method: 'POST',
						body: JSON.stringify({sentId, host}),
						headers: this.headers(accessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async sendGroupEmailMessage(accessToken, campaignId, journey_id, language, locationId, adminName){
				var host = window.location.host;
				const response = await fetch(`${this.urlPrefix}/api/group/survey/email`, {
						method: 'POST',
						body: JSON.stringify({campaignId, journey_id, language, locationId, host, adminName}),
						headers: this.headers(accessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async sendEmailMessage(userId, email, message, link, tvClient, locationId, url_id, journeyId, language){
				var host = window.location.host;
				const response1 = await fetch(`${this.urlPrefix}/api/survey/email`, {
						method: 'POST',
						body: JSON.stringify({email, message, link, language, host, locationId}),
						headers: this.headers(tvClient.accessToken)
				});

				const responseJson = await response1.json();
				const type = 'EMAIL';
				const response = 'Manual Email Msg';

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				} else{
						// eslint-disable-next-line
						const saveSentResponse = await fetch(`${this.urlPrefix}/api/survey/sent/save`, {
								method: 'POST',
								body: JSON.stringify({userId, type, response, locationId, url_id, journeyId}),
								headers: this.headers(tvClient.accessToken)
						});
				}

				return responseJson;
		}

		async getAuthTokens(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/reviewtokens`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getSsoJwt(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/reputationtoken`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getReviewLocations(tvAccessToken, type, accountId){
				const response = await fetch(`${this.urlPrefix}/api/reviewlocations?type=${type}&accountId=${accountId}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getRefreshedReviewLocations(tvAccessToken, type, accountId){
				const response = await fetch(`${this.urlPrefix}/api/refreshreviewlocations?type=${type}&accountId=${accountId}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getReviewAccounts(tvAccessToken, type){
				const response = await fetch(`${this.urlPrefix}/api/reviewaccounts?type=${type}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getRefreshedReviewAccounts(tvAccessToken, type){
				const response = await fetch(`${this.urlPrefix}/api/refreshreviewaccounts?type=${type}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getLocationReviews(tvAccessToken, locationId, type, currPage, maxPerPage){
				const response = await fetch(`${this.urlPrefix}/api/locationreviews?locationId=${locationId}&type=${type}&page=${currPage}&perPage=${maxPerPage}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async refreshGoogleLocationReviews(tvAccessToken, locationId, type, currPage, maxPerPage){
				const response = await fetch(`${this.urlPrefix}/api/refreshlocationreviews?locationId=${locationId}&type=${type}&page=${currPage}&perPage=${maxPerPage}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getDepartments(tvAccessToken,sort,page,perPage){
				const response = await fetch(`${this.urlPrefix}/api/departments?sort=${sort}&page=${page}&perPage=${perPage}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getStaff(tvAccessToken,sort,filter, page, perPage, locationId){
				const response = await fetch(`${this.urlPrefix}/api/staff?sort=${sort}&filter=${filter}&page=${page}&perPage=${perPage}&locationId=${locationId}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getTempStaff(tvAccessToken, page, perPage){
				const response = await fetch(`${this.urlPrefix}/api/tempstaff?&page=${page}&perPage=${perPage}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};


		async getMessages(tvAccessToken, filterType, filter, sort, page, perPage, locationFilter, campaignFilter){
				const response = await fetch(`${this.urlPrefix}/api/sent/messages?sort=${sort}&filter=${filter}&page=${page}&perPage=${perPage}&location=${locationFilter}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getSurveys(tvAccessToken,sort,filter, page, perPage, locationId){
				const response = await fetch(`${this.urlPrefix}/api/surveys?sort=${sort}&filter=${filter}&page=${page}&perPage=${perPage}&locationId=${locationId}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getIntakeSurveys(tvAccessToken,sort,filter, page, perPage, locationId){

				const response = await fetch(`${this.urlPrefix}/api/intake/surveys?sort=${sort}&filter=${filter}&page=${page}&perPage=${perPage}&locationId=${locationId}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getWinningSurveys(tvAccessToken){
				 const response = await fetch(`${this.urlPrefix}/api/surveys/winning`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async sendReviewReply(tvAccessToken, reviewId, reply){
				const response = await fetch(`${this.urlPrefix}/api/review/reply`, {
						method: 'POST',
						body: JSON.stringify({reviewId, reply}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		};

		async getPersonSpecificSurvey(tvAccessToken, id){
				const response = await fetch(`${this.urlPrefix}/api/survey/findperson`, {
						method: 'POST',
						body: JSON.stringify({id}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		};


		async getSpecificSurvey(tvAccessToken, id){
				const response = await fetch(`${this.urlPrefix}/api/survey/view`, {
						method: 'POST',
						body: JSON.stringify({id}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		};

		async getSpecificEvent(tvAccessToken, id){
				const response = await fetch(`${this.urlPrefix}/api/event/view`, {
						method: 'POST',
						body: JSON.stringify({id}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		};

		async getSpecificCustomEvent(tvAccessToken, id){
				const response = await fetch(`${this.urlPrefix}/api/event/custom/view`, {
						method: 'POST',
						body: JSON.stringify({id}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		};

		async updateSpecificEvent(tvAccessToken, eventId, action, resolved, adminName, category){

				const data = new FormData();

				data.append('eventId', eventId);
				data.append('action', action);
				data.append('resolved', resolved);
				data.append('adminName', adminName);
				data.append('category', category);
				// eslint-disable-next-line
				const response = await fetch(`${this.urlPrefix}/api/event/update`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});


		};

		async getPerson(tvAccessToken, personId){
				const response = await fetch(`${this.urlPrefix}/api/staff/${personId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getEndorsement(tvAccessToken, badgeId){
				const response = await fetch(`${this.urlPrefix}/api/endorsement/${badgeId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				

				const customMessages = {
					404: 'Endorsement not found',
				};

				if (response.status !== 200) {
					const errorMessage = handleResponseError(response.status, customMessages);
					throw new Error(errorMessage);
				}

				return response.json();
				
		};

		async getEhrMapping(tvAccessToken, mappingId){
				const response = await fetch(`${this.urlPrefix}/api/ehrmapping/${mappingId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getAdmin(tvAccessToken, personId){
				const response = await fetch(`${this.urlPrefix}/api/admin/${personId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async twoFactorCheck(tvAccessToken){

				const response = await fetch(`${this.urlPrefix}/api/admin/loginTFC`, {
						method: 'POST',
						body: JSON.stringify({}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;

		};

		async updateAdminReset(tvAccessToken, tvId){

				const response = await fetch(`${this.urlPrefix}/api/_surveys/admin/resettime`, {
						method: 'POST',
						body: JSON.stringify({tvId}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;

		};

		async getIntakeEnabledFlag(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/flags/intake`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};


		async getReviewsEnabledFlag(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/flags/reviews`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getAdminByTv(tvAccessToken, tvId){
				const response = await fetch(`${this.urlPrefix}/api/admin/tv/${tvId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async validatedMfa(tvAccessToken, tvId){

				const response = await fetch(`${this.urlPrefix}/api/_surveys/admin/mfaend`, {
						method: 'POST',
						body: JSON.stringify({tvId}),
						headers: this.headers(tvAccessToken.apiKey)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		};

		async generateMfaReq(tvAccessToken, tvId, mobile){

				const response = await fetch(`${this.urlPrefix}/api/_surveys/admin/mfareq`, {
						method: 'POST',
						body: JSON.stringify({tvId, mobile}),
						headers: this.headers(tvAccessToken.apiKey)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		};



		async getLocation(tvAccessToken, locationId){
				const response = await fetch(`${this.urlPrefix}/api/location/${locationId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getLocationReview(tvAccessToken, reviewId){
				const response = await fetch(`${this.urlPrefix}/api/review?reviewId=${reviewId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getCampaign(tvAccessToken, campaignId){
				const response = await fetch(`${this.urlPrefix}/api/campaign/${campaignId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getEnvFlags(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/reports/envflags`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getGroup(tvAccessToken, groupId){
				const response = await fetch(`${this.urlPrefix}/api/group/${groupId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getSpecificJourney(tvAccessToken, journeyId){
				const response = await fetch(`${this.urlPrefix}/api/journey/view/${journeyId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getDepartment(tvAccessToken, departmentId){
				const response = await fetch(`${this.urlPrefix}/api/department/${departmentId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};


		async getLocationMapping(tvAccessToken, mappingId){
				const response = await fetch(`${this.urlPrefix}/api/location/mapping/${mappingId}`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};


		async getOrgURLCode(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/settings/orgcode`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getDeptURLCodes(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/settings/deptcodes`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		};

		async getAllEndorsements(tvAccessToken, dept){

				const response = await fetch(`${this.urlPrefix}/api/_surveys/settings/endorsements?dept=${dept}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getSurveyLocationList(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/_surveys/survey/locations`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async retrieveStaffPointHistory(tvAccessToken, urlId, page){
				const response = await fetch(`${this.urlPrefix}/api/_surveys/staff/points/history?urlId=${urlId}&page=${page}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getOfficeLocations(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/_surveys/settings/locations`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getLocationMappingGroups(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/location/mapping/groups`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getCampaigns(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/campaigns`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getGroups(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/groups`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getEmployers(tvAccessToken){

				const response = await fetch(`${this.urlPrefix}/api/employers`, {
						method: 'POST',
						body: JSON.stringify({ }),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getRecentSurveys(tvAccessToken){

				const response = await fetch(`${this.urlPrefix}/api/surveys/sent/list`, {
						method: 'POST',
						body: JSON.stringify({ }),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getAdminUsers(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/settings/admins`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getClientSettings(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/settings/client`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getJourneys(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/journeys`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getJourneyMappings(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/journeys/mappings`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async enablePCCFunctionality(tvAccessToken, uuid){
				const response = await fetch(`${this.urlPrefix}/api/_surveys/emr/pcc/enable`, {
						method: 'POST',
						body: JSON.stringify({uuid}),
						headers: this.headers(tvAccessToken.accessToken)
				});

				return response.json();
		}

		async runKeywordCommand(tvAccessToken, commandId){
				const response = await fetch(`${this.urlPrefix}/api/settings/excom`, {
						method: 'POST',
						body: JSON.stringify({commandId}),
						headers: this.headers(tvAccessToken.accessToken)
				});

				return response.json();
		}

		async getLocationMappings(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/locations/mappings`, {
						headers: this.headers(tvAccessToken.accessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getGroupJourneys(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/journeys/groups`, {
						headers: this.headers(tvAccessToken.accessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async associatePatient(tvClient, tv_id, surveyId, respondent_type){

				const response = await fetch(`${this.urlPrefix}/api/_surveys/survey/associate`, {
						method: 'POST',
						body: JSON.stringify({tv_id, surveyId, respondent_type}),
						headers: this.headers(tvClient.apiKey)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async updateClientSettings(tvAccessToken, companyName, textMessage, emailMessage, employerMessage, logo, publicReviewEmail, intakeForm, intakeDept, peerPointConfig){
				var data = new FormData();
				data.append('companyName', companyName);
				data.append('textMessage', textMessage);
				data.append('emailMessage', emailMessage);
				data.append('employerMessage', employerMessage);
				data.append('peerPointConfig', peerPointConfig);
				data.append('publicReviewEmail', publicReviewEmail);
				data.append('intakeForm', intakeForm);
				data.append('intakeDept', intakeDept);
				data.append('files', logo);
				const response = await fetch(`${this.urlPrefix}/api/settings/update`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});
		}

		async updateIntakeResolution(tvAccessToken, survey_id){
				var data = new FormData();
				data.append('survey_id', survey_id);
				const response = await fetch(`${this.urlPrefix}/api/intake/status/update`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});
		}

		async updateRewardEntries(tvAccessToken, number, url_id){

				const response = await fetch(`${this.urlPrefix}/api/_surveys/survey/tickets/update`, {
						method: 'POST',
						body: JSON.stringify({number, url_id}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;

		}

		async trackReferReviewClicks(tvAccessToken, site, url_id){

			const response = await fetch(`${this.urlPrefix}/api/survey/track/reviewrefer`, {
				method: 'POST',
				body: JSON.stringify({site, url_id}),
				headers: this.headers(tvAccessToken)
			});
	
			const responseJson = await response.json();
	
			if(responseJson.length===0){
				responseJson.message = "Valid parameters not provided.";
			}
	
			return responseJson; 
	
		} 

		async createAdminUser(tvAccessToken, tvId, name, email, mobile, role, locations=[]){
				const data = new FormData();
				data.append('tvId', tvId);
				data.append('name', name);
				data.append('email', email);
				data.append('mobile', mobile);
				data.append('role', role);

				// eslint-disable-next-line
				const response = await fetch(`${this.urlPrefix}/api/admin/create`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

		}

		async sendDirectEmpSurvey(tvAccessToken, isEmail, message, campaignId, journeyId, language, locationId, adminName, tv_id, username, mobile, email){
				const data = new FormData();
				data.append('locationId', locationId);
				data.append('language', language);
				data.append('journeyId', journeyId);
				data.append('adminName', adminName);
				data.append('isEmail', isEmail);
				data.append('message', message);
				data.append('campaignId', campaignId);
				data.append('tv_id', tv_id);
				data.append('name', username);
				data.append('mobile', mobile);
				data.append('email', email);
				data.append('host', window.location.host);

				const response = await fetch(`${this.urlPrefix}/api/employer/survey/send`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				return response.json();

		}

		async beginGroupText(tvAccessToken, message, campaignId, journeyId, language, locationId){
				const data = new FormData();
				data.append('locationId', locationId);
				data.append('language', language);
				data.append('journeyId', journeyId);
				data.append('message', message);
				data.append('campaignId', campaignId);
				data.append('host', window.location.host);
				const response = await fetch(`${this.urlPrefix}/api/survey/group/send`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				return response.json();
		}

		async createPatientSurvey(tvAccessToken, locationid, language, journey, adminName){
				const data = new FormData();
				data.append('locationId', locationid);
				data.append('language', language);
				data.append('journeyId', journey);
				data.append('adminName', adminName);

				const response = await fetch(`${this.urlPrefix}/api/survey/patient/create`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				return response.json();
		}

		async createSpecificPatientSurvey(tvAccessToken,url_id, questions, language, locationId, tvPatientId){
			const data = new FormData();
				data.append('locationId', locationId);
				data.append('language', language);
				data.append('tvUser', tvPatientId);
				data.append('surveyUrl', url_id);
				data.append('questions', questions);

				const response = await fetch(`${this.urlPrefix}/api/patient/survey/create`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				return response.json();
		}

		async createDepartment(tvAccessToken, name, esname, departmentFiles, parentDepartment){
				const data = new FormData();
				data.append('files', departmentFiles);
				data.append('departmentName', name);
				data.append('esDepartmentName', esname);
				data.append('edit', 0);

				if(parentDepartment === null || parentDepartment===undefined || parentDepartment===''){
						parentDepartment = null;
				}
				data.append('parentDepartment', parentDepartment);

				const response = await fetch(`${this.urlPrefix}/api/department`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});
				if (response.status !== 201) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async updateJourney(tvAccessToken, journeyId, journeyName, departments, newFile) {
				const data = new FormData();
				data.append('files', newFile);
				data.append('name', journeyName);
				data.append('journeyId', journeyId);
				data.append('departments', departments);

				const response = await fetch(`${this.urlPrefix}/api/journey`, {
						method: 'PATCH',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}

		}

		async updateEhrMapping(tvAccessToken, journeyId, frequency, id, locationCode){
				const data = new FormData();

				data.append('journeyId', journeyId);
				data.append('frequency', frequency);
				data.append('locationCode', locationCode);
				data.append('id', id);

				const response = await fetch(`${this.urlPrefix}/api/ehrmapping`, {
						method: 'PATCH',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}


		async updateAdmin(tvAccessToken, name, mobile, id, role, locations=[]){
				const data = new FormData();

				data.append('name', name);
				data.append('mobile', mobile);
				data.append('id', id);
				data.append('role', role);
				data.append('locations', locations);

				const response = await fetch(`${this.urlPrefix}/api/admin`, {
						method: 'PATCH',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

	 async updateCampaign(tvAccessToken, campaignId, name, group){
				const data = new FormData();

				data.append('name', name);
				data.append('group', group);
				data.append('campaignId', campaignId);


				const response = await fetch(`${this.urlPrefix}/api/campaign`, {
						method: 'PATCH',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

	 async updateGroup(tvAccessToken, groupId, groupName){
				const data = new FormData();

				data.append('name', groupName);
				data.append('groupId', groupId);


				const response = await fetch(`${this.urlPrefix}/api/group`, {
						method: 'PATCH',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				// if (response.status !== 201) {
				// 		throw Error('Invalid response');
				// }

				const customMessages = {
					409: 'A group with this name already exists. Please choose a different name.',
					204: 'resource update successfully',
				};

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status, customMessages);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async sendIndFeedback(tvAccessToken, staffId){
				const data = new FormData();

				data.append('staffId', staffId);

				const response = await fetch(`${this.urlPrefix}/api/feedback/staff/individual`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				} else{
						return response.json();
				}
		}

		async sendIndFeedbackReminder(tvAccessToken, staffId){
				const data = new FormData();

				data.append('staffId', staffId);

				const response = await fetch(`${this.urlPrefix}/api/feedback/staff/individualreminder`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				} else{
						return response.json();
				}
		}

		async sendAllFeedback(tvAccessToken){
				const data = new FormData();

				const response = await fetch(`${this.urlPrefix}/api/feedback/staff/all`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				} else{
						return response.json();
				}
		}

		async sendAllFeedbackReminder(tvAccessToken){
				const data = new FormData();

				const response = await fetch(`${this.urlPrefix}/api/feedback/staff/allreminder`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				} else{
						return response.json();
				}
		}

		async sendDeptFeedback(tvAccessToken, deptId){
				const data = new FormData();

				data.append('deptId', deptId);

				const response = await fetch(`${this.urlPrefix}/api/feedback/staff/department`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				} else{
						return response.json();
				}
		}

		async sendDeptFeedbackReminder(tvAccessToken, deptId){
				const data = new FormData();

				data.append('deptId', deptId);

				const response = await fetch(`${this.urlPrefix}/api/feedback/staff/departmentreminder`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				} else{
						return response.json();
				}
		}

		async updateLocation(tvAccessToken, locationId, locationName, locationAddress1, locationAddress2, locationCity, locationState, locationZip, locationPhone, locationNotificationPhone,locationNotificationEmail1,locationNotificationEmail2, google_id, yelp_id, facebook_id, healthgrades_id){
				const data = new FormData();

				data.append('name', locationName);
				data.append('locationId', locationId);
				data.append('address1', locationAddress1);
				data.append('address2', locationAddress2);
				data.append('city', locationCity);
				data.append('state', locationState);
				data.append('zip', locationZip);
				data.append('locationPhone', locationPhone);
				data.append('locationNotificationPhone', locationNotificationPhone);
				data.append('locationNotificationEmail1', locationNotificationEmail1);
				data.append('locationNotificationEmail2', locationNotificationEmail2);
				data.append('google_id', google_id);
				data.append('yelp_id', yelp_id);
				data.append('facebook_id', facebook_id);
				data.append('healthgrades_id', healthgrades_id);

				const response = await fetch(`${this.urlPrefix}/api/location`, {
						method: 'PATCH',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async createMappedLocation(tvAccessToken, emrLocationId, locationName, locationAddress1, locationAddress2, locationCity, locationState, locationZip, locationPhone, locationNotificationPhone,locationNotificationEmail1,locationNotificationEmail2, google_id, yelp_id, facebook_id,healthgrades_id){
				const data = new FormData();

				data.append('emrLocationId', emrLocationId);
				data.append('name', locationName);
				data.append('address1', locationAddress1);
				data.append('address2', locationAddress2);
				data.append('city', locationCity);
				data.append('state', locationState);
				data.append('zip', locationZip);
				data.append('locationPhone', locationPhone);
				data.append('locationNotificationPhone', locationNotificationPhone);
				data.append('locationNotificationEmail1', locationNotificationEmail1);
				data.append('locationNotificationEmail2', locationNotificationEmail2);
				data.append('google_id', google_id);
				data.append('yelp_id', yelp_id);
				data.append('facebook_id', facebook_id);
				data.append('healthgrades_id', healthgrades_id);

				const response = await fetch(`${this.urlPrefix}/api/location/batch`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async createMultipleMappings(tvAccessToken, appointmentCode, journeyId, locationId, frequency){
				const data = new FormData();
				data.append('code', appointmentCode);
				data.append('journeyId', journeyId);
				data.append('locationId', locationId);
				data.append('frequency', frequency);

				const response = await fetch(`${this.urlPrefix}/api/multi/mappings/add`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async createMultipleStaff(tvAccessToken, staffUid, staffName, staffTitle, staffEmail, staffMobile,hireDate=null, birthDate=null, departments=null, locations=null, requestPhoto='N'){
				const data = new FormData();
				data.append('uid', staffUid);
				data.append('name', staffName);
				data.append('title', staffTitle);
				data.append('email', staffEmail);
				data.append('mobile', staffMobile);
				data.append('hireDate', hireDate);
				data.append('birthDate', birthDate);
				data.append('departments', departments);
				data.append('locations', locations);
				data.append('requestPhoto', requestPhoto);

				const response = await fetch(`${this.urlPrefix}/api/multi/staff/add`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async createCustomEvent(tvAccessToken, patientName, locationId, eventDetails, phoneNumber, adminName, category, staffId=null){
				const data = new FormData();

				data.append('name', patientName);
				data.append('locationId', locationId);
				data.append('details', eventDetails);
				data.append('phoneNumber', phoneNumber);
				data.append('adminName', adminName);
				data.append('category', category);
				data.append('staffId', staffId);

				const response = await fetch(`${this.urlPrefix}/api/event/custom/new`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async createLocation(tvAccessToken, locationName, locationAddress1, locationAddress2, locationCity, locationState, locationZip, locationPhone, locationNotificationPhone,locationNotificationEmail1,locationNotificationEmail2, google_id, yelp_id, facebook_id,healthgrades_id){
				const data = new FormData();

				data.append('name', locationName);
				data.append('address1', locationAddress1);
				data.append('address2', locationAddress2);
				data.append('city', locationCity);
				data.append('state', locationState);
				data.append('zip', locationZip);
				data.append('locationPhone', locationPhone);
				data.append('locationNotificationPhone', locationNotificationPhone);
				data.append('locationNotificationEmail1', locationNotificationEmail1);
				data.append('locationNotificationEmail2', locationNotificationEmail2);
				data.append('google_id', google_id);
				data.append('yelp_id', yelp_id);
				data.append('facebook_id', facebook_id);
				data.append('healthgrades_id', healthgrades_id);

				const response = await fetch(`${this.urlPrefix}/api/location`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}


		async addSinglePersonToGroup(tvAccessToken, name, email, number, group, orgName, tvid){
				const data = new FormData();

				data.append('name', name);
				data.append('number', number);
				data.append('email', email);
				data.append('orgName', orgName);
				data.append('group', group);
				data.append('tvid', tvid);

				const response = await fetch(`${this.urlPrefix}/api/groups/person/add`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						if(response.status===400){
								return { 'error': true, 'code': 'DuplicateEmail' };
						} else{
								throw Error('Invalid response');
						}
				}
		}

		async createCampaign(tvAccessToken, name, group){
				const data = new FormData();

				data.append('name', name);
				data.append('group', group);

				const response = await fetch(`${this.urlPrefix}/api/campaign`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async createGroup(tvAccessToken, groupName){
				const data = new FormData();

				data.append('name', groupName);

				const response = await fetch(`${this.urlPrefix}/api/group`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				// if (response.status !== 201) {
				// 		throw Error('Invalid response');
				// }

				const customMessages = {
					409: 'A group with this name already exists. Please choose a different name.',
				};

				if (response.status !== 201) {
					const errorMessage = handleResponseError(response.status, customMessages);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async createJourney(tvAccessToken, name, departments, journeyImg, groupOnly){
				const data = new FormData();
				data.append('files', journeyImg);
				data.append('name', name);
				data.append('departments', departments);
				data.append('groupOnly', groupOnly);

				const response = await fetch(`${this.urlPrefix}/api/journey`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}

		}

		async createJourneyMapping(tvAccessToken, apptCode, journeyCode, frequencyCode, locationCode){
				const data = new FormData();
				data.append('apptCode', apptCode);
				data.append('journeyCode', journeyCode);
				data.append('frequencyCode', frequencyCode);
				data.append('locationCode', locationCode);

				const response = await fetch(`${this.urlPrefix}/api/journey/mapping`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async creditPoints(tvAccessToken, staff_id, points, reason){
				const data = new FormData();
				data.append('points', points);
				data.append('reason', reason);
				data.append('staff_id', staff_id);

				const response = await fetch(`${this.urlPrefix}/api/staff/points/credit`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();

		}

		async redeemPoints(tvAccessToken, staff_id, points, reason){
				const data = new FormData();
				data.append('points', points);
				data.append('reason', reason);
				data.append('staff_id', staff_id);

				const response = await fetch(`${this.urlPrefix}/api/staff/points/redeem`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();

		}

		async createLocationMapping(tvAccessToken, emrCode, locationCode, locationGroup){
				const data = new FormData();
				data.append('emrCode', emrCode);
				data.append('locationCode', locationCode);
				data.append('locationGroup', locationGroup);

				const response = await fetch(`${this.urlPrefix}/api/location/mapping`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}

		}


		async createEndorsement(tvAccessToken, badgeName, badgeType, badgePriority, badgeLocalization, departments, endorsementFiles, points){
				const data = new FormData();
				data.append('files', endorsementFiles);
				data.append('badgeName', badgeName);
				data.append('badgeType', badgeType);
				data.append('badgePriority', badgePriority);
				data.append('badgeLocalization', badgeLocalization);
				data.append('departments', departments);
				data.append('points', points);

				const response = await fetch(`${this.urlPrefix}/api/endorsement`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
					const errorMessage = handleResponseError(response.status);
					throw new Error(errorMessage);
				}
		}

		async updateEndorsement(tvAccessToken, badgeId, badgeName, badgeType, badgePriority, badgeLocalization, departments, endorsementFiles,points){
				const data = new FormData();
				data.append('files', endorsementFiles);
				data.append('badgeName', badgeName);
				data.append('badgeType', badgeType);
				data.append('badgePriority', badgePriority);
				data.append('badgeLocalization', badgeLocalization);
				data.append('departments', departments);
				data.append('badgeId', badgeId);
				data.append('points', points);

				const response = await fetch(`${this.urlPrefix}/api/endorsement`, {
						method: 'PATCH',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async createStaffFromTemp(tvAccessToken,tempId, departments, locations, staffFiles,requestPhoto=false){

				const data = new FormData();

				if(staffFiles!==undefined && staffFiles !== null){
						data.append('files', staffFiles, 'blob' + Math.random().toString(36).substring(7) + 'upload.jpg');
				} else{
						data.append('files', staffFiles);
				}

				data.append('tempId', tempId);
				data.append('departments', departments);
				data.append('locations', locations);
				data.append('requestPhoto',requestPhoto);

				const response = await fetch(`${this.urlPrefix}/api/temptostaff/image/upload`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}

		}

		async createStaff(tvAccessToken, staffFiles, name, title, email, phone, bio, departments, locations, creator, active, approved, created, staffGoogleId, hireDate, birthDate, requestPhoto=false){
				const data = new FormData();

				if(staffFiles!==undefined && staffFiles !== null){
						data.append('files', staffFiles, 'blob' + Math.random().toString(36).substring(7) + 'upload.jpg');
				} else{
						data.append('files', staffFiles);
				}

				data.append('name', name);
				data.append('edit', 0);
				data.append('title', title);
				data.append('email', email);
				data.append('phone', phone);
				data.append('bio', bio);
				data.append('departments', departments);
				data.append('creator', creator);
				data.append('active', active);
				data.append('approved', approved);
				data.append('created', created);
				data.append('locations', locations);
				data.append('staffGoogleId', staffGoogleId);
				data.append('hireDate', hireDate);
				data.append('birthDate', birthDate);
				data.append('requestPhoto', requestPhoto);

				const response = await fetch(`${this.urlPrefix}/api/staff/image/upload`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}

		}

		async updateLocationMapping(tvAccessToken, mappingId, emrCode, locationGroup, locationId){
				const data = new FormData();

				data.append('mappingId', mappingId);
				data.append('emrCode', emrCode);
				data.append('locationGroup', locationGroup);
				data.append('locationId', locationId);

				const response = await fetch(`${this.urlPrefix}/api/location/mapping`, {
						method: 'PATCH',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async editDepartment(tvAccessToken, name, esname, departmentFiles, departmentId, parentDepartment){
				const data = new FormData();
				data.append('files', departmentFiles);
				data.append('departmentName', name);
				data.append('esDepartmentName', esname);
				data.append('edit', 1);
				data.append('departmentId', departmentId);


				if(parentDepartment === null || parentDepartment===undefined || parentDepartment===''){
						parentDepartment = null;
				}
				data.append('parentDepartment', parentDepartment);

				const response = await fetch(`${this.urlPrefix}/api/department`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async updateStaff(tvAccessToken,staffId, newFiles, name,title, email, phone, bio, departments, locations, creator,active, approved, staffGoogleId, hireDate, birthDate){
				const data = new FormData();


				if(newFiles!==undefined && newFiles !== null){
						data.append('files', newFiles, 'blob' + Math.random().toString(36).substring(7) + 'upload.jpg');
				} else{
						data.append('files', newFiles);
				}

				data.append('edit', 1);
				data.append('staffId', staffId);
				data.append('name', name);
				data.append('title', title);
				data.append('email', email);
				data.append('phone', phone);
				data.append('bio', bio);
				data.append('departments', departments);
				data.append('locations', locations);
				data.append('creator', creator);
				data.append('active', active);
				data.append('approved', approved);
				data.append('staffGoogleId', staffGoogleId);
				data.append('hireDate', hireDate);
				data.append('birthDate', birthDate);

				const response = await fetch(`${this.urlPrefix}/api/staff/image/upload`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async deleteDepartment(tvAccessToken, departmentId){
				const data = new FormData();
				data.append('departmentId', departmentId);
				const response = await fetch(`${this.urlPrefix}/api/department`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async deleteStaff(tvAccessToken, staffId){
				const data = new FormData();
				data.append('staffId', staffId);
				const response = await fetch(`${this.urlPrefix}/api/staff`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async requestTheirPhoto(tvAccessToken, staffId){
				const data = new FormData();
				data.append('staffId', staffId);
				const response = await fetch(`${this.urlPrefix}/api/staff/photo/request`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				}

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}


		async deleteTempStaff(tvAccessToken, staffId){
				const data = new FormData();
				data.append('staffId', staffId);
				const response = await fetch(`${this.urlPrefix}/api/tempstaff/delete`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						throw Error('Invalid response');
				}
		}

		async deleteAdmin(tvAccessToken, adminId){
				const data = new FormData();
				data.append('adminId', adminId);
				const response = await fetch(`${this.urlPrefix}/api/admin`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
						throw Error('Invalid response');
				}
		}

		async deleteGroupMember(tvAccessToken, groupId, memberId){
				const data = new FormData();
				data.append('groupId', groupId);
				data.append('memberId', memberId);
				const response = await fetch(`${this.urlPrefix}/api/group/member`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async deleteGroup(tvAccessToken, groupId){
				const data = new FormData();
				data.append('groupId', groupId);
				const response = await fetch(`${this.urlPrefix}/api/group`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async deleteCampaign(tvAccessToken, campaignId){
				const data = new FormData();
				data.append('campaignId', campaignId);
				const response = await fetch(`${this.urlPrefix}/api/campaign`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async deleteLocation(tvAccessToken, locationId){
				const data = new FormData();
				data.append('locationId', locationId);
				const response = await fetch(`${this.urlPrefix}/api/location`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async deleteEndorsement(tvAccessToken, endorsementId){
				const data = new FormData();
				data.append('endorsementId', endorsementId);
				const response = await fetch(`${this.urlPrefix}/api/endorsement`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}

				
		}

		async deleteJourney(tvAccessToken, journeyId){
				const data = new FormData();
				data.append('journeyId', journeyId);
				const response = await fetch(`${this.urlPrefix}/api/journey`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async deleteJourneyMapping(tvAccessToken, journeyMappingId){
				const data = new FormData();
				data.append('journeyMappingId', journeyMappingId);
				const response = await fetch(`${this.urlPrefix}/api/journey/mapping`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async deleteLocationMapping(tvAccessToken, locationMappingId){
				const data = new FormData();
				data.append('locationMappingId', locationMappingId);
				const response = await fetch(`${this.urlPrefix}/api/location/mapping`, {
						method: 'DELETE',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 204) {
					const errorMessage = handleResponseError(response.status);
					console.log(errorMessage)
					throw new Error(errorMessage);
				}
		}

		async updateSurveyForUser(tvAccessToken, surveyUrl){
				 const response = await fetch(`${this.urlPrefix}/api/_surveys/survey/update/ip`, {
						method: 'POST',
						body: JSON.stringify({surveyUrl}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async updateRequestPhoto(tvAccessToken, staffFiles, requestId){
				const data = new FormData();

				if(staffFiles!==undefined && staffFiles !== null){
						data.append('files', staffFiles, 'blob' + Math.random().toString(36).substring(7) + 'upload.jpg');
				}

				data.append('requestId', requestId);
				const response = await fetch(`${this.urlPrefix}/api/_surveys/requestphoto/image/upload`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				if (response.status !== 201) {
						console.log('Invalid response');
				}

		}

		async getRequestPhotoUrl(tvAccessToken, requestUrl){
				const response = await fetch(`${this.urlPrefix}/api/_surveys/requestphoto/${requestUrl}`, {
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid URL not provided.";
				}

				return responseJson;
		}

		async getSurveyUrl(tvAccessToken, surveyUrl) {
				const response = await fetch(`${this.urlPrefix}/api/_surveys/surveyurl/${surveyUrl}`, {
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid URL not provided.";
				}

				if(responseJson.staffForDepartments!==undefined && responseJson.staffForDepartments!==null){
						responseJson.staffForDepartments = this.returnStaffByDept(responseJson.staffForDepartments, responseJson.staffMapping, responseJson.teamStaffList);
				}

				return responseJson;
				//return response;
		}

		async getDeptStaff(tvAccessToken, departments) {

				const data = new FormData();
				data.append('departments', departments);

				const response = await fetch(`${this.urlPrefix}/api/_surveys/staff/find`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid departments not provided.";
				}

				return responseJson;
		}

		async findStaffByName(tvAccessToken, query){
				const data = new FormData();
				data.append('name', query);
				const response = await fetch(`${this.urlPrefix}/api/_surveys/staffing/byname`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				const responseJson = await response.json();
				const options = responseJson.map((i) => ({
						staffimageid: i.icon,
						staff_id: i.staff_id.toString(),
						name: i.name,
						department: i.departments[0],
						department_id: i.departments[0],
						departments: i.departments,
						title: i.title
				}));

				return options;
		}

		async findStaffByNameOnly(tvAccessToken, query){
				const data = new FormData();
				data.append('name', query);
				const response = await fetch(`${this.urlPrefix}/api/staffing/bynameonly`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				const responseJson = await response.json();
				const options = responseJson.map((i) => ({
						staffimageid: i.icon,
						staff_id: i.staff_id.toString(),
						name: i.name,
						department: i.department_id,
						title: i.title
				}));

				return options;
		}

		async getStaffExport(tvAccessToken, locationId){
				const data = new FormData();
				data.append('locationId', locationId);

				const response = await fetch(`${this.urlPrefix}/api/staff/export`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				const responseJson = await response.json();
				return responseJson;

		}

		async getActivePeerStaffExport(tvAccessToken, locationId, dateFilter){
				const data = new FormData();
				data.append('locationId', locationId);
				data.append('dateFilter', dateFilter);

				const response = await fetch(`${this.urlPrefix}/api/staff/activepeer/export`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				const responseJson = await response.json();
				return responseJson;
		}

		async getRecognizedPeerStaffExport(tvAccessToken, locationId, dateFilter){
				const data = new FormData();
				data.append('locationId', locationId);
				data.append('dateFilter', dateFilter);

				const response = await fetch(`${this.urlPrefix}/api/staff/recognizedpeer/export`, {
						method: 'POST',
						body: data,
						headers: this.fileheaders(tvAccessToken)
				});

				const responseJson = await response.json();
				return responseJson;
		}

		async executePersonalSurvey(tvAccessToken, surveyId){
				const response = await fetch(`${this.urlPrefix}/api/_surveys/survey/execute`, {
						method: 'POST',
						body: JSON.stringify({surveyId}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async updateSurvey(tvAccessToken, surveyDetails, surveyId, currentState, forceNew, language){
				var host = window.location.host;
				const response = await fetch(`${this.urlPrefix}/api/_surveys/survey/sync`, {
						method: 'POST',
						body: JSON.stringify({surveyDetails, surveyId, currentState, forceNew, language,host}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;

		}

		async updateSurveyPersonDetails(tvAccessToken, url_id, name, phone, email){
			var host = window.location.host;
			const response = await fetch(`${this.urlPrefix}/api/_surveys/identify`, {
				method: 'POST',
				body: JSON.stringify({url_id, name, phone, email, host}),
				headers: this.headers(tvAccessToken)
			});
	
			const responseJson = await response.json();
	
			if(responseJson.length===0){
				responseJson.message = "Valid parameters not provided.";
			}
	
			return responseJson; 
	
		}

		async getExistingSurvey(tvAccessToken, surveyUrl){

				const response = await fetch(`${this.urlPrefix}/api/_surveys/existingsurvey/${surveyUrl}`, {
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid URL not provided.";
				}

				return responseJson;
		}

		async checkValidEmpUrl(tvAccessToken, surveyUrl){
				const response = await fetch(`${this.urlPrefix}/api/_surveys/empsurveycheck/${surveyUrl}`, {
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid URL not provided.";
				}

				return responseJson;
		}

		async getOrgSurveyUrl(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/_surveys/orgsurvey`, {
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid URL not provided.";
				}

				return responseJson;
		}

		async getDeviceSurvey(tvAccessToken, surveyUrl){
				const response = await fetch(`${this.urlPrefix}/api/_surveys/devicesurvey?surl=${surveyUrl}`, {
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid URL not provided.";
				}

				if(responseJson.staffForDepartments!==undefined && responseJson.staffForDepartments!==null){
						responseJson.staffForDepartments = this.returnStaffByDept(responseJson.staffForDepartments, responseJson.staffMapping, responseJson.teamStaffList);
				}

				return responseJson;
		}

		async getAdminDashboardSurveys(tvAccessToken, locationList=null){
				let commaSeparatedValues = '';
				if(locationList!==null){
						commaSeparatedValues = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/dashboard/surveydata?locations=${commaSeparatedValues}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getAdminDashboardNps(tvAccessToken, filter, businessLine=null, journey = null, locationList=null){
				let locations = '';
				if(locationList!==null){
						locations = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/dashboard/npsdata`, {
						method: 'POST',
						body: JSON.stringify({filter, businessLine, journey, locations}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getAdminDashboardOnlyNps(tvAccessToken, filter, businessLine=null, journey = null, locationList=null){

				let locations = '';

				if(locationList!==null){
						locations = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/dashboardonly/npsdata`, {
						method: 'POST',
						body: JSON.stringify({filter, businessLine, locations}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getCustomAdminDashboardNps(tvAccessToken, fromDate, toDate, businessLine = null, journey = null, locationList=null){
				 let locations = '';

				if(locationList!==null){
						locations = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/npsdata`, {
						method: 'POST',
						body: JSON.stringify({fromDate, toDate, businessLine, journey, locations}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async viewStaffNpsData(tvAccessToken, filter, staffId, departmentId, locationId, fromDate, toDate){
				const response = await fetch(`${this.urlPrefix}/api/reports/staffnpsdata`, {
						method: 'POST',
						body: JSON.stringify({filter, staffId, departmentId, locationId, fromDate, toDate}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getReportsLocationNps(tvAccessToken, filter, businessLine=null, journey = null, locationList=null){

				let locations = '';
				if(locationList!==null){
						locations = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/reports/locationnpsdata`, {
						method: 'POST',
						body: JSON.stringify({filter, businessLine, journey, locations}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;

		}

		async getCustomReportsLocationNps(tvAccessToken, fromDate, toDate, businessLine=null, journey=null, locationList=null){
				let locations = '';
				if(locationList!==null){
						locations = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/locationnpsdata`, {
						method: 'POST',
						body: JSON.stringify({fromDate, toDate, businessLine, journey, locations}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;

		}

		async getCustomReportsEmployerSurveyOverviewData(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate){

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/employersurveyoverviewdata`, {
						method: 'POST',
						body: JSON.stringify({fromDate, toDate, dateFilter, campaignId, employer}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getCustomReportsLocationNpsByLocation(tvAccessToken, fromDate, toDate, locationId){

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/locationnpsdatabylocation`, {
						method: 'POST',
						body: JSON.stringify({fromDate, toDate, locationId}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getCustomReportsEmployerEndorsementData(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate){

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/employerendorsementdata`, {
						method: 'POST',
						body: JSON.stringify({fromDate, toDate, dateFilter, campaignId, employer}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getCustomReportsEmployerNpsData(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate){

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/employernpsdata`, {
						method: 'POST',
						body: JSON.stringify({fromDate, toDate, dateFilter, campaignId, employer}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getAdminDashboardBadgeData(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/dashboard/topendorsements`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async listGiftReport(tvAccessToken, location, filter, fromDate, toDate, locationList=null){
			let locations = '';
			if(locationList!==null){
					locations = locationList.join(',');
			}

			const response = await fetch(`${this.urlPrefix}/api/reports/giftreport`, {
					method: 'POST',
					body: JSON.stringify({location, filter, fromDate, toDate, locations}),
					headers: this.headers(tvAccessToken)
			});

			const responseJson = await response.json();

			if(responseJson.length===0){
					responseJson.message = "Valid parameters not provided.";
			}

			return responseJson;
		}

		async getAdminDashboardBadgeDataReports(tvAccessToken, location, filter, fromDate, toDate, businessLine=null, locationList=null){

				let locations = '';
				if(locationList!==null){
						locations = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/topendorsements`, {
						method: 'POST',
						body: JSON.stringify({location, filter, fromDate, toDate, businessLine, locations}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getSpecificEndorsementReport(tvAccessToken, location, filter, fromDate, toDate, endorsementId){

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/specificendorsement`, {
						method: 'POST',
						body: JSON.stringify({location, filter, fromDate, toDate, endorsementId}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}


		async saveWinningSurvey(tvAccessToken, survey_id){

				const response = await fetch(`${this.urlPrefix}/api/survey/winner/save`, {
						method: 'POST',
						body: JSON.stringify({survey_id}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getWinningSurvey(tvAccessToken, fromDate, toDate, locationId=0){

				const response = await fetch(`${this.urlPrefix}/api/survey/winner/draw`, {
						method: 'POST',
						body: JSON.stringify({fromDate, toDate, locationId}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getAdminDashboardDeptEngagement(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/dashboard/deptengagementwithstaff`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getAdminDashboardActionableEvents(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/dashboard/actionableevents`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getAdminDashboardStaffEngagement(tvAccessToken){
				const response = await fetch(`${this.urlPrefix}/api/dashboard/staffengagement`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getPaginatedPeerStaffFeedbackReport(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate){

			 const response = await fetch(`${this.urlPrefix}/api/reports/custom/peerstafffeedback`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getPaginatedStaffFeedbackWithDetailsReport(tvAccessToken, filter, location, dateFilter, fromDate, toDate, businessLine=null){
		       const response = await fetch(`${this.urlPrefix}/api/reports/custom/stafffeedbackwithdetails`, {
		            method: 'POST',
		            body: JSON.stringify({filter, location, dateFilter, fromDate, toDate, businessLine}),
		            headers: this.headers(tvAccessToken)
		        });
		
		        const responseJson = await response.json();
		
		        if(responseJson.length===0){
		            responseJson.message = "Valid parameters not provided.";
		        }
		
		        return responseJson;
		}
			
		async getPaginatedStaffFeedbackReport(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, businessLine=null){

			 const response = await fetch(`${this.urlPrefix}/api/reports/custom/stafffeedback`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate, businessLine}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getPaginatedIndividualStaffFeedbackReport(tvAccessToken, sort, filter, department, page, perPage, location, dateFilter, fromDate, toDate){

			 const response = await fetch(`${this.urlPrefix}/api/reports/custom/stafffeedback`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, department, page, perPage, location, dateFilter, fromDate, toDate}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getPaginatedDeptFeedbackReport(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate){

			 const response = await fetch(`${this.urlPrefix}/api/reports/custom/deptfeedback`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getCampaignResultList(tvAccessToken, campaignId){

				const response = await fetch(`${this.urlPrefix}/api/reports/campaign/results`, {
						method: 'POST',
						body: JSON.stringify({campaignId}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getEmployerWrittenFeedbackReport(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate){

			 const response = await fetch(`${this.urlPrefix}/api/reports/employer/feedback/written`, {
						method: 'POST',
						body: JSON.stringify({campaignId, employer, dateFilter, fromDate, toDate}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}



		async getPaginatedStaffEngagement(tvAccessToken, sort, filter, page, perPage){
				const response = await fetch(`${this.urlPrefix}/api/reports/staffengagement?sort=${sort}&filter=${filter}&page=${page}&perPage=${perPage}`, {
						headers: this.headers(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getPaginatedStaffPeerPointReport(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept){

			 const response = await fetch(`${this.urlPrefix}/api/reports/custom/peerstaffpointreport`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getPaginatedPeerStaffEngagementReport(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept){

			 const response = await fetch(`${this.urlPrefix}/api/reports/custom/peerstaffengagement`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getAllIntakeStats(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, journey=null, dept, businessLine=null){

			 const response = await fetch(`${this.urlPrefix}/api/reports/custom/intakestats`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept, businessLine, journey}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}


		async getPaginatedStaffEngagementReport(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept, businessLine=null, locationList=null){

				let locations = '';

				if(locationList!==null){
						locations = locationList.join(',');
				}

			 const response = await fetch(`${this.urlPrefix}/api/reports/custom/staffengagement`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept, businessLine, locations}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async listPublicReviewReportStart(tvAccessToken, type, dateFilter, fromDate, toDate){
						const response = await fetch(`${this.urlPrefix}/api/reports/publicreview/results`, {
						method: 'POST',
						body: JSON.stringify({type, dateFilter, fromDate, toDate}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getPaginatedIndividualStaffEngagementReport(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept, staff){

			 const response = await fetch(`${this.urlPrefix}/api/reports/custom/individualstaffengagement`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate, dept, staff}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}



		async getEmployerDeptEngagementReport(tvAccessToken, campaignId, employer, dateFilter, fromDate, toDate){

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/employerdeptengagement`, {
						method: 'POST',
						body: JSON.stringify({campaignId, employer, dateFilter, fromDate, toDate}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getPaginatedPeerDeptEngagementReport(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate){

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/peerdeptengagement`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getPaginatedDeptEngagementReport(tvAccessToken, sort, filter, page, perPage, location, dateFilter, fromDate, toDate, locationList){

				let locations = '';

				if(locationList!==null){
						locations = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/reports/custom/deptengagement`, {
						method: 'POST',
						body: JSON.stringify({sort, filter, page, perPage, location, dateFilter, fromDate, toDate, locations}),
						headers: this.headers(tvAccessToken)
				});

				const responseJson = await response.json();

				if(responseJson.length===0){
						responseJson.message = "Valid parameters not provided.";
				}

				return responseJson;
		}

		async getPaginatedDeptEngagement(tvAccessToken, sort, filter, page, perPage){
				const response = await fetch(`${this.urlPrefix}/api/reports/deptengagement?sort=${sort}&filter=${filter}&page=${page}&perPage=${perPage}`, {
						headers: this.headers(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getPaginatedActionableEventsByPatient(tvAccessToken, userId, input, page, perPage, sendAll){
				const response = await fetch(`${this.urlPrefix}/api/reports/actionableeventsbypatient?page=${page}&perPage=${perPage}&userId=${userId}&input=${input}&sendAll=${sendAll}`, {
						headers: this.headers(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getPaginatedActionableEvents(tvAccessToken, sort, filter, page, perPage, showNames=false, sendAll=false, locationList=null){
				let commaSeparatedValues = '';
				if(locationList!==null){
						commaSeparatedValues = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/reports/actionableevents?sort=${sort}&filter=${filter}&page=${page}&perPage=${perPage}&showNames=${showNames}&sendAll=${sendAll}&locations=${commaSeparatedValues}`, {
						headers: this.headers(tvAccessToken)
				});

				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getAdminDashboardOverview(tvAccessToken, filter, businessLine=null, journey = null, locationList=null){
				let commaSeparatedValues = '';
				if(locationList!==null){
						commaSeparatedValues = locationList.join(',');
				}
				const response = await fetch(`${this.urlPrefix}/api/dashboard/overviewdata?filter=${filter}&businessLine=${businessLine}&journey=${journey}&locations=${commaSeparatedValues}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getAdminDashboardOnlyOverview(tvAccessToken, filter, businessLine=null, journey = null, locationList=null){
				let commaSeparatedValues = '';
				if(locationList!==null){
						commaSeparatedValues = locationList.join(',');
				}

				const response = await fetch(`${this.urlPrefix}/api/dashboardonly/overviewdata?filter=${filter}&locations=${commaSeparatedValues}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}


		async getAdminDashboardOverviewCustom(tvAccessToken, filter, fromDate, toDate, businessLine=null, journey=null, locationList=null){
				let commaSeparatedValues = '';
				if(locationList!==null){
						commaSeparatedValues = locationList.join(',');
				}
				const response = await fetch(`${this.urlPrefix}/api/dashboard/overviewdata?filter=${filter}&fromDate=${fromDate}&toDate=${toDate}&businessLine=${businessLine}&journey=${journey}&locations=${commaSeparatedValues}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getPeerLocationEndorsementDetail(tvAccessToken, filter, location, fromDate=null, toDate=null){
				const response = await fetch(`${this.urlPrefix}/api/reports/peer/location/badges?filter=${filter}&location=${location}&fromDate=${fromDate}&toDate=${toDate}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getPeerLocationStaffActivityDetail(tvAccessToken, filter, location, fromDate=null, toDate=null){
				const response = await fetch(`${this.urlPrefix}/api/reports/peer/location/activity?filter=${filter}&location=${location}&fromDate=${fromDate}&toDate=${toDate}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getPeerLocationFeedbackDetail(tvAccessToken, filter, location, fromDate=null, toDate=null){
				const response = await fetch(`${this.urlPrefix}/api/reports/peer/location/feedback?filter=${filter}&location=${location}&fromDate=${fromDate}&toDate=${toDate}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getAdminLocationOverviewData(tvAccessToken, filter, location, fromDate, toDate){
				const response = await fetch(`${this.urlPrefix}/api/dashboard/locationoverviewdata?filter=${filter}&location=${location}&fromDate=${fromDate}&toDate=${toDate}`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getPeerSummaryByLocation(tvAccessToken, dateFilter, startDate=undefined, endDate=undefined){
				const response = await fetch(`${this.urlPrefix}/api/reports/peers/locationsummary?dateFilter=${dateFilter}&fromDate=${startDate}&toDate=${endDate}`, {						
					headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}

		async getAdminDashboardStats(tvAccessToken) {
				const response = await fetch(`${this.urlPrefix}/api/dashboard/stats`, {
						headers: this.headers(tvAccessToken)
				});
				if (response.status !== 200) {
						throw Error('Invalid response');
				}
				return response.json();
		}
}

export default InternalApiClient;
