import React, {Component} from "react";
import {Button} from "react-bootstrap";
import { CSVLink } from 'react-csv';
import PropTypes from "prop-types";


class ExportTopBehaviorsCSV extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    cleanData(dirtyData){
        let exportData = [];
        let comboData = dirtyData.comboBadgeData;
        let notUsedData = dirtyData.notUsed;
        let totalBadges = 0;
        for(var j=0; j<comboData.length; j++){
            totalBadges = totalBadges + parseInt(comboData[j].total);
            if(comboData[j].severitylevel!==undefined && comboData[j].severitylevel!==null){
                comboData[j].description+=' - ' + comboData[j].severitylevel;
            }
        }
        for(var i=0; i<notUsedData.length; i++){
            notUsedData[i].total = '0';
            notUsedData[i].usage = '0';
        }
        for(var z=0; z<comboData.length; z++){
            comboData[z].usage = Number.parseFloat(parseInt(comboData[z].total)/totalBadges*100).toFixed(1);
        }


        exportData = comboData.concat(notUsedData);

        return exportData;
    }

    allHeaders(dirtyData){
        let headers = [];
        headers.push({ 'label': 'ID', 'key': 'endorsement_id'});
        headers.push({ 'label': 'Name', 'key': 'description'});
        headers.push({ 'label': 'Total', 'key': 'total'}); 
        headers.push({ 'label': 'Percent Usage', 'key': 'usage'}); 
        return headers;
    }

    render() {
        return (
            <Button className='float-right btn btn-primary exportButton'>
                <CSVLink data={this.cleanData(this.props.csvData)} headers={this.allHeaders(this.props.csvData)} filename={this.props.fileName}>Export CSV</CSVLink>
            </Button>
        )

    }
}

ExportTopBehaviorsCSV.propTypes = {
    csvData: PropTypes.object.isRequired,
    fileName: PropTypes.string.isRequired
};

export default ExportTopBehaviorsCSV;
